import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidenavService {
  private sidenavExpanded: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  sidenavExpanded$ = this.sidenavExpanded.asObservable();

  toggleSideNav(value: boolean) {
    this.sidenavExpanded.next(value);
  }
}
