import { Injectable } from '@angular/core';
import Shepherd from 'shepherd.js';
import { SidenavService } from './layouts/main/sidenav.service';

@Injectable()
export class TourService {
  nextButtonClasses = 'bg-blue-400 text-white rounded-[50px]';
  skipButtonClasses = 'bg-slate-900 text-white rounded-[50px]';
  private tour = new Shepherd.Tour({
    defaultStepOptions: {
      classes:
        'bg-slate-700 rounded-2xl pt-[0.6rem] px-[0.25rem] pb-[0.25rem] mt-3',
    },
    useModalOverlay: true,
  });
  disable = false;
  constructor(private sidenavService: SidenavService) {}
  skipAll() {
    const tourStr = localStorage.getItem('tour-done');
    const tour = JSON.parse(tourStr);

    tour['skipAll'] = true;

    localStorage.setItem('tour-done', JSON.stringify(tour));
  }
  initTourInStorage() {
    const tour = {
      skipAll: false,
      steps: {},
    };
    localStorage.setItem('tour-done', JSON.stringify(tour));
  }
  removeAllToursFromLocalStorage() {
    const tour = {
      skipAll: false,
      steps: {},
    };
    localStorage.setItem('tour-done', JSON.stringify(tour));
  }
  setStepDone(forTour: string) {
    const tourStr = localStorage.getItem('tour-done');
    const tour = JSON.parse(tourStr);
    tour.steps[forTour] = true;
    localStorage.setItem('tour-done', JSON.stringify(tour));
  }
  isNeeded(forTour: string) {
    // If the service is disable, ignore all.
    if (this.disable) return false;
    const tourStr = localStorage.getItem('tour-done');
    if (tourStr) {
      // Tour was initiated before
      try {
        const tour = JSON.parse(tourStr);
        if (tour.steps[forTour] || tour.skipAll === true) {
          // This tour was  done, user doesn't need to do it.
          return false;
        } else {
          // User didn't do the tour.
          return true;
        }
      } catch (e) {
        // Something went wrong, need to re-initaize the steps.
        this.initTourInStorage();
        this.setStepDone(forTour);

        return true;
      }
    } else {
      // Tour was never initated, usually first ever open of codenteam
      this.initTourInStorage();
      this.setStepDone(forTour);

      return true;
    }
  }
  resetTour() {
    if (this.tour.isActive()) this.tour.complete();
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        classes:
          'bg-slate-700 rounded-2xl pt-[0.6rem] px-[0.25rem] pb-[0.25rem] mt-3',
      },
      useModalOverlay: true,
    });
  }

  homeTour() {
    if (!this.isNeeded('home')) return;
    this.resetTour();
    this.tour.addStep({
      id: 'step1',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Welcome to Codenteam!</h1> <div class="text-white text-[18px]">Codenteam is your companion for comprehensive development project ownership analysis. Whether you want insights on a company, team, or individual developer level, Codenteam has got you covered.</div>`,
      buttons: [
        {
          text: 'Start tour',
          action: () => {
            this.sidenavService.toggleSideNav(true);
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step1',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Restart Tour</h1> <div class="text-white text-[18px]">You can restart this tour whenever you want by clicking the Help button</div>`,
      attachTo: {
        element: '[data-test="start-tour"]',
        on: 'top-start',
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.sidenavService.toggleSideNav(false);
            this.setStepDone('home');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step2',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Getting Started</h1> <div class="text-white">To start your analysis journey, you'll need to create your first analysis. Simply click on the "Create New Run" button to kickstart the process. </div>`,
      attachTo: {
        element: '[data-test="create-run"]',
        on: 'bottom',
      },
      canClickTarget: true,
      highlightClass: 'highlightClass',
      buttons: [],
    });
    this.tour.start();
  }

  importTour() {
    if (!this.isNeeded('import')) return;
    this.resetTour();

    this.tour.addStep({
      id: 'step3',
      text: '<h1 class="text-white font-medium text-[22px] mb-2">Import Your Code</h1> <div class="text-white">Your code can be sourced from GitHub or a public repository of your choice. This step is crucial for data collection.</div>',
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step22',
      text: '<h1 class="text-white font-medium text-[22px] mb-2">Public Clone Link</h1> <div class="text-white">For the sake of this tour, we will populate the link for you!</div>',
      attachTo: {
        element: '[data-test="import-using-clone-link"]',
        on: 'top-end',
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: false,
      advanceOn: {
        event: 'click',
        selector: 'input[data-test="import-using-link"]',
      },
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.setStepDone('import');
            this.tour.next();
            const inputElement = document.querySelector(
              'input[data-test="import-using-link"]'
            ) as HTMLInputElement;
            if (inputElement) {
              inputElement.value =
                'https://github.com/Codenteam/analysis-demo.git';
              inputElement.dispatchEvent(new Event('input', { bubbles: true }));
            }
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.addStep({
      id: 'step23',
      text: '<h1 class="text-white font-medium text-[22px] mb-2">Start Importing</h1> <div class="text-white">Click "Import" and your analysis is 2 minutes away!</div>',
      attachTo: {
        element: '[data-test="import-bttn"]',
        on: 'bottom',
      },
      advanceOn: {
        event: 'click',
        selector: '[data-test="import-bttn"]',
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      buttons: [
        // {
        //   text: 'Skip Tour',
        //   action: () => {
        //     this.tour.cancel();
        //     this.skipAll();
        //   },
        //   classes: this.skipButtonClasses,
        // },
      ],
    });
    this.tour.start();
  }

  gitHubImportTour() {
    if (!this.isNeeded('github')) return;
    this.resetTour();
    this.tour.addStep({
      id: 'step4',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">GitHub Integration</h1> <div class="text-white">If you've chosen to import code from GitHub, you'll need to select your organization and repository. This ensures precise data retrieval for analysis.</div>`,
      attachTo: {
        element: '[data-test="github-installations"]',
        on: 'right',
      },
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.setStepDone('github');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.start();
  }

  profileTour() {
    if (!this.isNeeded('profile')) return;
    this.resetTour();
    this.tour.addStep({
      id: 'step5',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Alias Management</h1> <div class="text-white">Assigning aliases to profiles is an essential step to enhance the accuracy of your analysis. Begin by grouping similar names and emails together for more meaningful results.</div>`,
      attachTo: {
        element: '[data-test="auto-assign-bttn"]',
        on: 'bottom',
      },
      highlightClass: 'highlightClass',
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      advanceOn: {
        event: 'click',
        selector: '[data-test="auto-assign-bttn"]',
      },
      buttons: [
        // {
        //   text: 'Skip Tour',
        //   action: () => {
        //     this.tour.cancel();
        //     this.skipAll();
        //   },
        //   classes: this.skipButtonClasses,
        // },
      ],
    });

    this.tour.addStep({
      id: 'step51',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Alias Management</h1> <div class="text-white">Auto-profile matcher tries to group same developer commits under different aliases into one. Please verify the results after done.</div>`,
      attachTo: {
        element: '[data-test="auto-profiles-matching-bttn"]',
        on: 'bottom',
      },
      highlightClass: 'highlightClass',
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      advanceOn: {
        event: 'click',
        selector: '[data-test="auto-profiles-matching-bttn"]',
      },
      buttons: [
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step52',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Alias Management</h1> <div class="text-white">This process would override your existing profile if you have any</div>`,
      highlightClass: 'highlightClass',
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.addStep({
      id: 'step6',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Profile Editing</h1> <div class="text-white">Make any necessary edits to your profiles as required. This step allows you to fine-tune the analysis for better insights.</div>`,
      attachTo: {
        element: '[data-test="profiles-container"]',
        on: 'bottom',
      },
      highlightClass: 'highlightClass',
      canClickTarget: false,
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.setStepDone('profile');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.start();
  }
  teamTour() {
    if (!this.isNeeded('team')) return;
    this.resetTour();
    this.tour.addStep({
      id: 'step7',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Team Assignment</h1> <div class="text-white">Profiles need to be assigned to teams. Aligning profiles with your company's organizational structure helps in a more comprehensive assessment of team dynamics.</div>`,
      canClickTarget: false,
      scrollTo: { behavior: 'smooth', block: 'start' },
      attachTo: {
        element: '[data-test="profiles-container-in-teams"]',
        on: 'right-start',
      },
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    // Select first
    this.tour.addStep({
      id: 'step7',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Select Developer to assign to team</h1> <div class="text-white">Now let's select the first developer and assign to team, to do so, click on the developer </div>`,
      attachTo: {
        element: "[data-test='on-hold-developer-item']:first-child",
        on: 'top-end',
      },
      highlightClass: 'highlightClass',
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      advanceOn: {
        event: 'click',
        selector: '[data-test=on-hold-developer-item]:first-child',
      },
      buttons: [
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    // Assign
    this.tour.addStep({
      id: 'step7',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Assign to team</h1> <div class="text-white">Now to assign to new team you can either drag to the right, drop in the area saying "Drop here to create new team" if you are on desktop, alternatively you can use the "+ Create new Team" button.</div>`,
      attachTo: {
        element: "[data-test='create-new-team-button']",
        on: 'top',
      },
      highlightClass: 'highlightClass',
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      advanceOn: {
        event: 'click',
        selector: '[data-test=create-new-team-button]',
      },
      buttons: [
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step7',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Now assign remaining profiles</h1> <div class="text-white">One more developer to go!</div>`,
      attachTo: {
        element: "[data-test='on-hold-developer-item']:first-child",
        on: 'bottom',
      },
      highlightClass: 'highlightClass',
      scrollTo: { behavior: 'smooth', block: 'center' },
      canClickTarget: true,
      advanceOn: {
        event: 'click',
        selector: '[data-test=on-hold-developer-item]:first-child',
      },
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.setStepDone('team');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
      ],
    });

    this.tour.start();
  }

  analysisTour() {
    if (!this.isNeeded('analysis')) return;
    this.resetTour();

    this.tour.addStep({
      id: 'step8',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Company Overview</h1> <div class="text-white">Explore the company page for an overall view of your organization's ownership landscape. Here, you'll find valuable information, such as ownership trends among ex-employees, highest and lowest ownership within teams and developers, both overall and over the last 15 days.</div>`,
      canClickTarget: true,
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: this.tour.next,
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.addStep({
      id: 'step8(2)',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Team Analysis</h1> <div class="text-white">For a closer look at individual teams, simply select the team you want to analyze from the right-hand side. This allows for a more focused examination of team-specific ownership.</div>`,
      canClickTarget: true,
      scrollTo: { behavior: 'smooth', block: 'center' },
      attachTo: {
        element: '[data-test="teams-block-in-company-page"]',
        on: 'top-start',
      },
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.setStepDone('analysis');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.start();
  }

  teamAnalysisTour() {
    if (!this.isNeeded('team-analysis')) return;
    this.resetTour();

    this.tour.addStep({
      id: 'step8',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Team Analysis </h1> <div class="text-white">Deep dive into the team page to gain comprehensive insights into your organization's dynamic team landscape. Discover key details, including trends in team composition, ownership distribution among current and former members, and notable fluctuations in ownership levels among team members and developers, offering a comprehensive snapshot of team dynamics both historically and within the recent 30-day timeframe.</div>`,
      canClickTarget: true,
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: this.tour.next,
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step9(2)',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Developer Analysis</h1> <div class="text-white">Deep dive into individual developer profiles to gain insights into their ownership contributions. Select an individual developer for a detailed breakdown of their involvement.</div>`,
      canClickTarget: true,
      attachTo: {
        element: '[data-test="choose-team-bttn"]:first-child',
        on: 'bottom',
      },
      buttons: [
        {
          text: 'OK',
          action: () => {
            this.setStepDone('team-analysis');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.start();
  }

  profileAnalysisTour() {
    if (!this.isNeeded('profile-analysis')) return;
    this.resetTour();
    this.tour.addStep({
      id: 'step11',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Developer In-depth Analysis</h1> <div class="text-white">In developer analysis page, you will uncover important information such as ownership trends for specific developer, as well as any fluctuations over the past 30 days, as well as ownership in each code depth.</div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.addStep({
      id: 'step11',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Comparison Tool</h1> <div class="text-white">If you're interested in comparing ownership between two developers or two teams, the compare menu is at your disposal. It enables you to assess ownership dynamics and identify trends.</div>`,
      attachTo: {
        element: '[data-test="main-menu"]',
        on: 'bottom',
      },
      highlightClass: 'highlightClass',
      canClickTarget: false,
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.sidenavService.toggleSideNav(true);
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.addStep({
      id: 'step12',
      text: `<h1 class="text-white font-medium text-[22px] mb-2">Detailed Code Analysis</h1> <div class="text-white">For a granular analysis, start your examination at the code level. This feature enables you to visualize ownership per developer, team, or ex-employees for every module, at various depth levels, ensuring you have a complete picture of your project's ownership structure.</div>`,
      attachTo: {
        element: '[data-test="code-analysis"]',
        on: 'bottom',
      },
      highlightClass: 'highlightClass',
      canClickTarget: false,
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.setStepDone('profile-analysis');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });
    this.tour.addStep({
      id: 'step13',
      text: `<div class="text-white">Report tab has overall details about all sides of ownership in one view</div>`,
      attachTo: {
        element: '[data-test="code-analysis-report"]',
        on: 'bottom',
      },
      advanceOn: {
        event: 'click',
        selector: '[data-test="code-analysis-report"]',
      },
      highlightClass: 'highlightClass',
      canClickTarget: true,
      scrollTo: { behavior: 'smooth', block: 'center' },
    });

    this.tour.start();
  }

  ReportTour() {
    if (!this.isNeeded('report')) return;
    this.resetTour();

    this.tour.addStep({
      id: 'step14',
      text: `<div class="text-white text-[18px]">The report is splited into 3 parts: Company-wide notes, team related notes and code related notes</div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step15',
      text: `<div class="text-white text-[18px]">Company-wide notes</div>`,
      attachTo: {
        element: '[data-test="report-company-notes"]',
        on: 'top',
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step16',
      text: `<div class="text-white text-[18px]">Team related notes</div>`,
      attachTo: {
        element: '[data-test="report-teams-notes"]',
        on: 'top',
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip Tour',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step17',
      text: `<div class="text-white text-[18px]">Code related notes</div>`,
      attachTo: {
        element: '[data-test="report-code-notes"]',
        on: 'top',
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      buttons: [
        {
          text: 'Done',
          action: () => {
            this.setStepDone('report');
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
      ],
    });
    this.tour.start();
  }
  ///////////////////////////////////////////////////
  jiraTour(forceStart: boolean) {
    if (forceStart === false) {
      if (!this.isNeeded('jira')) return;
      this.resetTour();
    }

    this.tour.addStep({
      id: 'step60',
      text: `<div class="text-white text-[18px]">To Integrate Jira, follow the following steps</div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step61',
      text: `<div class="text-white text-[18px]">install Codenteam app on your Jira environment.</div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step62',
      text: `<div class="text-white text-[18px]">Install the app and follow the on-screen steps.</div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step63',
      text: `<div class="flex">
      <p class="text-white text-[18px]">Once installed, go to Apps menu and select Codenteam Setup.</p>
      <img
            src="../assets/jira.svg"
            class="!h-6 !w-6 pl-1"
          />
      </div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step64',
      text: `<div class="flex">
      <p class="text-white text-[18px]">In the Codenteam Setup screen, click on Sync button, you need to be logged into Codenteam before that.</p>
      <img
            src="../assets/jira.svg"
            class="!h-6 !w-6 pl-1"
          />
      </div>`,
      buttons: [
        {
          text: 'Next',
          action: () => {
            this.tour.next();
          },
          classes: this.nextButtonClasses,
        },
        {
          text: 'Skip',
          action: () => {
            this.tour.cancel();
            this.skipAll();
          },
          classes: this.skipButtonClasses,
        },
      ],
    });

    this.tour.addStep({
      id: 'step65',
      text: `<div class="flex">
      <p class="text-white text-[18px]">Once clicked, if all went well, you see a “Successfully Synced” Message</p>
      <img
            src="../assets/jira.svg"
            class="!h-6 !w-6 pl-1"
          />
      </div>`,
      buttons: [
        {
          text: 'Done',
          action: () => {
            this.setStepDone('jira');
            this.tour.cancel();
          },
          classes: this.nextButtonClasses,
        },
      ],
    });
    this.tour.start();
  }
}
